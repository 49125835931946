import React from "react";
import LvoFoundColumn from "../charts/LvoFoundColumn";
import {Tab, Tabs} from "react-bootstrap";
import {filterData} from "../utils/Utils";

class ContentLvo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            xAxis: 'prehospital.race',
            xAxisTitle: 'RACE',
            xCatStrict: true,
            xCategories: [{eq: 0}, {eq: 1}, {eq: 2}, {eq: 3}, {eq: 4}, {eq: 5}, {eq: 6}, {eq: 7}, {eq: 8}, {eq: 9}],
            yCatStrict: true,
            yCategories: [{eq: 'Виявлено при КТ чи МР скануванні'}, {eq: 'Виявлено при ДСЦА'}, {eq: 'Не виявлено'}]
        };

        this.toggleX = this.toggleX.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            data: filterData(nextProps.data, nextProps.filters)
        }
    }

    toggleX(e) {
        switch (e) {
            case 'fasted' :
                this.setState(state => ({
                    xAxis: 'prehospital.fasted',
                    xAxisTitle: 'FAST-ED',
                    xCatStrict: true,
                    xCategories: [{eq:0}, {eq:1}, {eq:2}, {eq:3}, {eq:4}, {eq:5}, {eq:6}, {eq:7}, {eq:8}, {eq:9}]
                }));
                break;
            case 'comglasgo' :
                this.setState(state => ({
                    xAxis: 'prehospital.comglasgo',
                    xAxisTitle: 'ком Глазго',
                    xCatStrict: true,
                    xCategories: [
                        {eq:3}, {eq:4}, {eq:5}, {eq:6}, {eq:7}, {eq:8}, {eq:9},
                        {eq:10}, {eq:11}, {eq:12}, {eq:13}, {eq:14}, {eq:15}
                    ]
                }));
                break;
            case 'mozok' :
                this.setState(state => ({
                    xAxis: 'mozok',
                    xAxisTitle: 'МОЗОК-2',
                    xCatStrict: true,
                    xCategories: [{eq:1}, {eq:2}]
                }));
                break;
            case 'nihss-grouped' :
                this.setState(state => ({
                    xAxis: 'nihss.start',
                    xAxisTitle: 'NIHSS з групуванням',
                    xCatStrict: false,
                    xCategories: [{to: 4}, {from: 5, to: 15}, {from: 16, to: 20}, {from: 21}]
                }));
                break;
/*
            case 'nihss-grouped2' :
                this.setState(state => ({
                    xAxis: 'nihss.start',
                    xAxisTitle: 'NIHSS з групуванням - неврологія',
                    xCatStrict: false,
                    xCategories: [{to: 7}, {from: 8, to: 15}, {from: 16}]
                }));
                break;
*/
            default:
                this.setState(state => ({
                    xAxis: 'prehospital.race',
                    xAxisTitle: 'RACE',
                    xCatStrict: true,
                    xCategories: [{eq: 0}, {eq: 1}, {eq: 2}, {eq: 3}, {eq: 4}, {eq: 5}, {eq: 6}, {eq: 7}, {eq: 8}, {eq: 9}]
                }));
                break;
        }
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="race" onSelect={(e) => this.toggleX(e)} className="mb-3 restyle">
                    <Tab eventKey="race" title="RACE"></Tab>
                    <Tab eventKey="fasted" title="FAST-ED"></Tab>
                    <Tab eventKey="comglasgo" title="ком Глазго"></Tab>
                    <Tab eventKey="mozok" title="МОЗОК-2"></Tab>
                    <Tab eventKey="nihss-grouped" title="NIHSS з групуванням"></Tab>
{/*
                    <Tab eventKey="nihss-grouped2" title="NIHSS з групуванням - 2"></Tab>
*/}
                </Tabs>

                <div className="mixed-chart compact">
                    <LvoFoundColumn
                        id="plot"
                        data={this.state.data}
                        title={'Статистика виявлення LVO'}
                        yAxis="lvoDiscovery"
                        yAxisTitle="LVO"
                        width="800"
                        xAxis={this.state.xAxis}
                        xAxisTitle={this.state.xAxisTitle}
                        xCategories={this.state.xCategories}
                        xCatStrict={this.state.xCatStrict}
                        yCategories={this.state.yCategories}
                        yCatStrict={this.state.yCatStrict}
                    />
                </div>

            </div>
        );
    }
}

export default ContentLvo;