import React from "react";
import Chart from "react-apexcharts";
import {getValueByPath} from "../utils/Utils";
import {forEach} from "react-bootstrap/ElementChildren";

class MultiAxisChart extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            grouping: 'month'
        };

        this.state.series = [];

        this.state.options = {
            chart: {
                defaultLocale: 'ua',
                locales: [
                    {
                        "name": "ua",
                        "options": {
                            "months": [
                                "Січень",
                                "Лютий",
                                "Березень",
                                "Квітень",
                                "Травень",
                                "Червень",
                                "Липень",
                                "Серпень",
                                "Вересень",
                                "Жовтень",
                                "Листопад",
                                "Грудень"
                            ],
                            "shortMonths": [
                                "Січ",
                                "Лют",
                                "Бер",
                                "Кві",
                                "Тра",
                                "Чер",
                                "Лип",
                                "Сер",
                                "Вер",
                                "Жов",
                                "Лис",
                                "Гру"
                            ],
                            "days": [
                                "Неділя",
                                "Понеділок",
                                "Вівторок",
                                "Середа",
                                "Четвер",
                                "П'ятниця",
                                "Субота"
                            ],
                            "shortDays": ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                            "toolbar": {
                                "exportToSVG": "Зберегти SVG",
                                "exportToPNG": "Зберегти PNG",
                                "exportToCSV": "Зберегти CSV",
                                "menu": "Меню",
                                "selection": "Вибір",
                                "selectionZoom": "Вибір із збільшенням",
                                "zoomIn": "Збільшити",
                                "zoomOut": "Зменшити",
                                "pan": "Переміщення",
                                "reset": "Скинути збільшення"
                            }
                        }
                    },
                    {
                        "name": "en",
                        "options": {
                            "months": [
                                "January",
                                "February",
                                "March",
                                "April",
                                "May",
                                "June",
                                "July",
                                "August",
                                "September",
                                "October",
                                "November",
                                "December"
                            ],
                            "shortMonths": [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec"
                            ],
                            "days": [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                            "toolbar": {
                                "exportToSVG": "Download SVG",
                                "exportToPNG": "Download PNG",
                                "exportToCSV": "Download CSV",
                                "menu": "Menu",
                                "selection": "Selection",
                                "selectionZoom": "Selection Zoom",
                                "zoomIn": "Zoom In",
                                "zoomOut": "Zoom Out",
                                "pan": "Panning",
                                "reset": "Reset Zoom"
                            }
                        }
                    }
                ],
                height: 350,
                type: 'area',
                stacked: false,
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: false
                },
                toolbar: {
                    autoSelected: 'zoom',
                    tools: {
                        download: false
                    }
                }
            },
            dataLabels: {
                enabled: false
            },
            markers: {
                size: 0,
            },
            title: {
                text: 'Успішність тромбектомій',
                align: 'left'
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
            yaxis: [{
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0) + "%";
                    },
                },
                min: 0,
                max: 100,
                title: {
                    text: 'eTICI 2B67 або краще'
                },
            }, {
                opposite: true,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0);
                    },
                },
                title: {
                    text: 'Всього виконано тромбектомій'
                }
            }],
            xaxis: {
                type: 'datetime',
            },
/*
            tooltip: {
                shared: true
                /!*y: {
                    formatter: function (val) {
                        return (val / 1000000).toFixed(0)
                    }
                }*!/
            }
*/
        };
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        if (currentState.data === nextProps.data && currentState.xAxis === nextProps.xAxis) {
            return null;
        } else {
            return MultiAxisChart.processData(nextProps.data, nextProps);
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined) {
            this.setState(MultiAxisChart.processData(this.props.data, this.props));
        }
    }

    static processData(rawData, props) {
        const newData = [{
            name: 'Відсоток успішних тромбектомій',
            type: 'area',
            data: []
        }, {
            name: 'Всього виконано тромбектомій',
            type: 'bar',
            data: []
        }
        ];

        const dataMap = new Map();
        for (let i = 0; i < rawData.length; i++) {
            let key;
            switch (props.grouping) {
                case 'year':
                    key = new Date("01/01/" + getValueByPath(rawData[i], "year")).getTime();
                    break;
                case 'quarter':
                    let q = 1+3*Math.floor((getValueByPath(rawData[i], "month") - 1) / 3);
                    key = new Date(q + "/01/" + getValueByPath(rawData[i], "year")).getTime();
                    break;
                default:
                    key = new Date(getValueByPath(rawData[i], "month") + "/01/" + getValueByPath(rawData[i], "year")).getTime();
            }
            if (!dataMap.has(key)) {
                dataMap.set(key, {'total': 0, 'successful': 0})
            }
            dataMap.get(key).total += getValueByPath(rawData[i], "trombextractions.total");
            dataMap.get(key).successful += getValueByPath(rawData[i], "trombextractions.successful");
        }


        dataMap.forEach((value, key)=>{
            let valuePercent = 0;
            if (value.total > 0) {
                valuePercent = 100 * value.successful / value.total;
            }
            newData[0].data.push([key, valuePercent]);
            newData[1].data.push([key, value.total])
        })

        return {
            series: newData
        };
    }

    render() {
        return (
            <div>
                <div className="mixed-chart">
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="area"
                        width={this.props.width}
                    />
                </div>
            </div>
        );
    }
}

export default MultiAxisChart;