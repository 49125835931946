import React from "react";
import Chart from "react-apexcharts";
import {getLabelValue, getValueByPath, testValue} from "../utils/Utils";

class TimeBar extends React.Component {

    constructor(props) {
        super(props);

        const yNames = [];
        for (let i = 0; i < props.yCategories.length; i++) {
            yNames[i] = getLabelValue(props.yCatStrict, props.yCategories[i]);
        }

        this.state = {
            data: props.data,

            yAxis: props.yAxis,
            yCategories: props.yCategories,
            yCatStrict: props.yCatStrict,
            xCategories: props.xCategories,

            series: [],

            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    defaultLocale: 'ua',
                    locales: [
                        {
                            "name": "ua",
                            "options": {
                                "months": [
                                    "Січень",
                                    "Лютий",
                                    "Березень",
                                    "Квітень",
                                    "Травень",
                                    "Червень",
                                    "Липень",
                                    "Серпень",
                                    "Вересень",
                                    "Жовтень",
                                    "Листопад",
                                    "Грудень"
                                ],
                                "shortMonths": [
                                    "Січ",
                                    "Лют",
                                    "Бер",
                                    "Кві",
                                    "Тра",
                                    "Чер",
                                    "Лип",
                                    "Сер",
                                    "Вер",
                                    "Жов",
                                    "Лис",
                                    "Гру"
                                ],
                                "days": [
                                    "Неділя",
                                    "Понеділок",
                                    "Вівторок",
                                    "Середа",
                                    "Четвер",
                                    "П'ятниця",
                                    "Субота"
                                ],
                                "shortDays": ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                                "toolbar": {
                                    "exportToSVG": "Зберегти SVG",
                                    "exportToPNG": "Зберегти PNG",
                                    "exportToCSV": "Зберегти CSV",
                                    "menu": "Меню",
                                    "selection": "Вибір",
                                    "selectionZoom": "Вибір із збільшенням",
                                    "zoomIn": "Збільшити",
                                    "zoomOut": "Зменшити",
                                    "pan": "Переміщення",
                                    "reset": "Скинути збільшення"
                                }
                            }
                        },
                        {
                            "name": "en",
                            "options": {
                                "months": [
                                    "January",
                                    "February",
                                    "March",
                                    "April",
                                    "May",
                                    "June",
                                    "July",
                                    "August",
                                    "September",
                                    "October",
                                    "November",
                                    "December"
                                ],
                                "shortMonths": [
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec"
                                ],
                                "days": [
                                    "Sunday",
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday",
                                    "Saturday"
                                ],
                                "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                                "toolbar": {
                                    "exportToSVG": "Download SVG",
                                    "exportToPNG": "Download PNG",
                                    "exportToCSV": "Download CSV",
                                    "menu": "Menu",
                                    "selection": "Selection",
                                    "selectionZoom": "Selection Zoom",
                                    "zoomIn": "Zoom In",
                                    "zoomOut": "Zoom Out",
                                    "pan": "Panning",
                                    "reset": "Reset Zoom"
                                }
                            }
                        }
                    ],
                    toolbar: {
                        show: false,
                        tools: {
                            download: false
                        }
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                title: {
                    text: 'Розподіл витрат часу - ' + props.yAxisTitle
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                xaxis: {
                    labels: {
                        formatter: function (val) {
                            //return Math.floor(val/60) + " год. " + Math.floor(val % 60) + " хв."
                            return val + ' хв.';
                        }
                    },
                    categories: yNames,
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return Math.floor(val/60) + " год. " + Math.floor(val % 60) + " хв.";
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                    offsetX: 40
                }
            }
        };
        if (this.props.stackType !== undefined) {
            this.state.options.chart.stackType = this.props.stackType;
        }
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        if (currentState.data === nextProps.data && currentState.yAxis === nextProps.yAxis) {
            return null;
        } else {
            return TimeBar.processData(nextProps.data, nextProps, currentState.options);
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined) {
            this.setState(TimeBar.processData(this.props.data, this.props, this.state.options));
        }
    }

    static processData(rawData, props, options) {
        const yNames = [];
        for (let i = 0; i < props.yCategories.length; i++) {
            yNames[i] = getLabelValue(props.yCatStrict, props.yCategories[i]);
        }

        const groupedData = new Array(props.xCategories.length);
        for (let i = 0; i < groupedData.length; i++) {
            groupedData[i] = [];
            for (let j = 0; j < props.yCategories.length; j++) {
                groupedData[i][j] = [];
            }
        }
        for (let i = 0; i < rawData.length; i++) {
            if (getValueByPath(rawData[i], 'trombextraction.done') === 'Ні') {
                continue;
            }
            for (let j = 0; j < props.yCategories.length; j++) {
                if (testValue(getValueByPath(rawData[i], props.yAxis), props.yCatStrict, props.yCategories[j])) {
                    for (let k = 0; k < props.xCategories.length; k++) {
                        if (props.xCategories[k].getTime(rawData[i]) > 0) {
                            groupedData[k][j].push(props.xCategories[k].getTime(rawData[i]));
                        }
                    }
                }
            }
        }
        let ser = [];
        const average = (array) => array.reduce((a, b) => a + b) / array.length;
        for (let i = 0; i < props.xCategories.length; i++) {
            ser[i] = [];
            ser[i]['name'] = getLabelValue(props.xCatStrict, props.xCategories[i]);
            ser[i]['data'] = [];
            for (let j = 0; j < props.yCategories.length; j++) {
                if (groupedData[i][j].length > 0) {
                    ser[i]['data'][j] = Math.round(average(groupedData[i][j]));
                } else {
                    ser[i]['data'][j] = 0;
                }
            }
        }
        return {
            width: props.width,
            options: {
                ...options,
                xaxis: {
                    categories: yNames
                },
                title: {
                    text: 'Розподіл витрат часу - ' + props.yAxisTitle + ' '
                },
                z: Math.random() // workaround to trigger refresh when options do not change
            },
            yAxis: props.yAxis,
            yCategories: props.yCategories,
            yCatStrict: props.yCatStrict,
            yAxisTitle: props.yAxisTitle,
            series: ser
        };
    }

    render() {
        return (
            <div>
                <div className="mixed-chart">
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        width={this.state.width}
                    />
                </div>
            </div>
        );
    }
}



export default TimeBar;