import React from "react";
import MultiAxisChart from "../charts/MultiAxisChart";
import {Tab, Tabs} from "react-bootstrap";
import {filterData} from "../utils/Utils";

class ContentHospital extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            xAxis: 'gender',
            xAxisTitle: 'Стать',
            xCatStrict: true,
            xCategories: [{eq:'Ч'}, {eq:'Ж'}]
        };

        this.toggleGrouping = this.toggleGrouping.bind(this);
    }

    toggleGrouping(e) {
        switch (e) {
            case 'quarter' :
                this.setState(state => ({
                    ...this.state,
                    grouping: 'quarter'
                }));
                break;
            case 'year' :
                this.setState(state => ({
                    ...this.state,
                    grouping: 'year'
                }));
                break;
            default :
                this.setState(state => ({
                    ...this.state,
                    grouping: 'month'
                }));
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            data: filterData(nextProps.data, nextProps.filters)
        }
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="month" onSelect={(e) => this.toggleGrouping(e)} className="mb-3 restyle">
                    <Tab eventKey="month" title="Місяці"></Tab>
                    <Tab eventKey="quarter" title="Квартали"></Tab>
                    <Tab eventKey="year" title="Роки"></Tab>
                </Tabs>

                <div className="mixed-chart">
                    <MultiAxisChart
                        id="chart1"
                        data={this.state.data}
                        grouping={this.state.grouping}
                        width="800"

                    />
                </div>

            </div>
        );
    }
}

export default ContentHospital;