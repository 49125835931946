export function getValueByPath(obj, path) {
    let splitPath = path.split(".");
    let value = obj;
    splitPath.forEach(pathEntry => {
        value = value[pathEntry];
    });
    return value;
}

export function filterData(data, filters) {
    let resData = [];
    if (filters === undefined) {
        return resData;
    }
    for (let i = 0; i < data.length; i++) {
        if ( (filters.hospital === '' || filters.hospital === data[i].hospital) &&
            (filters.region === '' || filters.region === data[i].region) ) {
            resData.push(data[i]);
        }
    }
    return resData;
}

export function getFilterValues(data, filters) {
    let regions = new Set();
    let hospitals = new Set();
    for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < data[i].length; j++) {
            regions.add(data[i][j].region);
            if (filters === undefined || filters.region === '' || filters.region === data[i][j].region) {
                hospitals.add(data[i][j].hospital);
            }
        }
    }
    return {
        regions: Array.from(regions),
        hospitals: Array.from(hospitals)
    };
}

export function getLabelValue(isStrict, categoryDefinition) {
    if (categoryDefinition.name !== undefined) {
        return '' + categoryDefinition.name;
    }
    if (isStrict) {
        return '' + categoryDefinition.eq;
    }
    if (categoryDefinition.from !== undefined && categoryDefinition.to !== undefined) {
        return '' + categoryDefinition.from + '-' + categoryDefinition.to;
    }
    if (categoryDefinition.to !== undefined) {
        return '≤'+categoryDefinition.to;
    }
    return '≥'+categoryDefinition.from;
}

export function testValue(value, isStrict, categoryDefinition) {
    if (isStrict) {
        if (value === categoryDefinition.eq) {
            return true;
        }
        return false;
    }
    if (value === "") {
        return false;
    }
    if (categoryDefinition.from !== undefined && value < categoryDefinition.from) {
        return false;
    }
    if (categoryDefinition.to !== undefined && value > categoryDefinition.to) {
        return false;
    }
    return true;
}