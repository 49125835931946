import React from "react";
import BoxPlot from "../charts/BoxPlot";
import {Tabs} from "react-bootstrap";
import {Tab} from "react-bootstrap";
import {filterData} from "../utils/Utils";

class ContentMrs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            xAxis: 'gender',
            xAxisTitle: 'Стать',
            xCatStrict: true,
            xCategories: [{eq:'Ч'}, {eq:'Ж'}]
        };

        this.toggleX = this.toggleX.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            data: filterData(nextProps.data, nextProps.filters)
        }
    }

    toggleX(e) {
        switch (e) {
            case 'age' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'age',
                    xAxisTitle: 'Вік',
                    xCatStrict: false,
                    xCategories: [{from:18,to:30}, {from:31,to:50}, {from:51, to:60}, {from:61, to:70}, {from:71, to:80}, {from:81}]
                }));
                break;
            case 'covid19-pcr' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'covid19.pcr',
                    xAxisTitle: 'ПЦР тест Covid-19',
                    xCatStrict: true,
                    xCategories: [{eq:'Так'}, {eq:'Ні'}, {eq:'Не проводився'}]
                }));
                break;
            case 'diabetes' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'diabetes',
                    xAxisTitle: 'Супутній діабет',
                    xCatStrict: true,
                    xCategories: [{eq:'Так'}, {eq:'Ні'}]
                }));
                break;
            case 'route' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'route',
                    xAxisTitle: 'Маршрут',
                    xCatStrict: true,
                    xCategories: [{eq:'Трансфер'}, {eq:'Локальний'}]
                }));
                break;
            case 'urban' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'urban',
                    xAxisTitle: 'Міський/сільский мешканець',
                    xCatStrict: true,
                    xCategories: [{eq: 'Міський'}, {eq: 'Сільський'}, {eq: 'Внутрішньо-лікарняний'}]
                }));
                break;
            case 'time-to-door' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'doorTime.timeToDoor',
                    xAxisTitle: 'Час до прибуття в лікарню',
                    xCatStrict: false,
                    xCategories: [
                        {to:30}, {from:31, to:60}, {from:61, to:90}, {from:91, to:120},
                        {from:121, to:150}, {from:151, to:180}, {from:181, to:210}, {from:211, to:240},
                        {from:241, to:270}, {from:271, to:300}, {from:301, to:330}, {from:331, to:360},
                        {from:361}
                    ]
                }));
                break;
            case 'door-to-needle' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'trombolisis.doorToNeedle',
                    xAxisTitle: 'Час до початку тромболізісу',
                    xCatStrict: false,
                    xCategories: [
                        {to:30}, {from:31, to:60}, {from:61, to:90}, {from:91, to:120},
                        {from:121, to:150}, {from:151, to:180}, {from:181, to:210}, {from:211, to:240},
                        {from:241, to:270}, {from:271, to:300}, {from:301, to:330}, {from:331, to:360},
                        {from:361}
                    ]
                }));
                break;
            case 'door-to-groine-puncture' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'trombextraction.doorToGroinePuncture',
                    xAxisTitle: 'Час до початку тромбектомії',
                    xCatStrict: false,
                    xCategories: [
                        {to:30}, {from:31, to:60}, {from:61, to:90}, {from:91, to:120},
                        {from:121, to:150}, {from:151, to:180}, {from:181, to:210}, {from:211, to:240},
                        {from:241, to:270}, {from:271, to:300}, {from:301, to:330}, {from:331, to:360},
                        {from:361}
                    ]
                }));
                break;
            case 'door-to-recanalization' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'trombextraction.doorToRecanalization',
                    xAxisTitle: 'Час до реканалізації',
                    xCatStrict: false,
                    xCategories: [
                        {to:30}, {from:31, to:60}, {from:61, to:90}, {from:91, to:120},
                        {from:121, to:150}, {from:151, to:180}, {from:181, to:210}, {from:211, to:240},
                        {from:241, to:270}, {from:271, to:300}, {from:301, to:330}, {from:331, to:360},
                        {from:361}
                    ]
                }));
                break;
            case 'eTICI' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'trombextraction.eTICI',
                    xAxisTitle: 'eTICI',
                    xCatStrict: true,
                    xCategories: [{eq: 0}, {eq: 1}, {eq: '2A'}, {eq: '2B50'}, {eq: '2B67'}, {eq: '2C'}, {eq: 3}]
                }));
                break;
            case 'aspects' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'aspects',
                    xAxisTitle: 'ASPECTS за результатами КТ або МРТ',
                    xCatStrict: true,
                    xCategories: [
                        {eq: 0}, {eq: 1}, {eq: 2}, {eq: 3}, {eq: 4}, {eq: 5},
                        {eq: 6}, {eq: 7}, {eq: 8}, {eq: 9}, {eq: 10}
                    ]
                }));
                break;
            case 'nihss.start' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'nihss.start',
                    xAxisTitle: 'NIHSS при госпіталізації',
                    xCatStrict: false,
                    xCategories: [{to: 4}, {from: 5, to: 15}, {from: 16, to: 20}, {from: 21}]
                }));
                break;
            case 'lvo' :
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'lvoDiscovery',
                    xAxisTitle: 'LVO виявлено',
                    xCatStrict: true,
                    xCategories: [{eq: 'Виявлено при КТ чи МР скануванні'}, {eq: 'Виявлено при ДСЦА'}, {eq: 'Не виявлено'}]
                }));
                break;

            default:
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'gender',
                    xAxisTitle: 'Стать',
                    xCatStrict: true,
                    xCategories: [{eq:'Ч'}, {eq:'Ж'}]
                }));
                break;
        }
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="gender" onSelect={(e) => this.toggleX(e)} className="mb-3 restyle">
                    <Tab eventKey="gender" title="Стать"></Tab>
                    <Tab eventKey="age" title="Вік"></Tab>
                    <Tab eventKey="covid19-pcr" title="Covid-19"></Tab>
                    <Tab eventKey="diabetes" title="Супутній діабет"></Tab>
                    <Tab eventKey="route" title="Маршрут"></Tab>
                    <Tab eventKey="urban" title="Міський/сільский мешканець"></Tab>
                    <Tab eventKey="time-to-door" title="Час до прибуття в лікарню"></Tab>
                    <Tab eventKey="door-to-needle" title="Час до початку тромболізісу"></Tab>
                    <Tab eventKey="door-to-groine-puncture" title="Час до початку тромбектомії"></Tab>
                    <Tab eventKey="door-to-recanalization" title="Час до реканалізації"></Tab>
                    <Tab eventKey="eTICI" title="eTICI"></Tab>
                    <Tab eventKey="aspects" title="ASPECTS за результатами КТ або МРТ"></Tab>
                    <Tab eventKey="nihss.start" title="NIHSS при госпіталізації"></Tab>
                    <Tab eventKey="lvo" title="LVO виявлено"></Tab>
                </Tabs>

                <div className="mixed-chart compact">
                    <BoxPlot
                        id="plot1"
                        data={this.state.data}
                        yAxis="mRS24h"
                        yAxisTitle="mRS через 24 години"
                        yAxisMin={0}
                        yAxisMax={6}
                        width="800"
                        height="200"
                        xAxis={this.state.xAxis}
                        xAxisTitle={this.state.xAxisTitle}
                        xCategories={this.state.xCategories}
                        xCatStrict={this.state.xCatStrict}
                    />
                </div>
                <div className="mixed-chart compact">
                    <BoxPlot
                        id="plot2"
                        data={this.state.data}
                        yAxis="mRSLeave"
                        yAxisTitle="mRS при виписці"
                        yAxisMin={0}
                        yAxisMax={6}
                        width="800"
                        height="200"
                        xAxis={this.state.xAxis}
                        xAxisTitle={this.state.xAxisTitle}
                        xCategories={this.state.xCategories}
                        xCatStrict={this.state.xCatStrict}
                    />
                </div>
                <div className="mixed-chart compact">
                    <BoxPlot
                        id="plot3"
                        data={this.state.data}
                        yAxis="mRS90d"
                        yAxisTitle="mRS через 90 днів"
                        yAxisMin={0}
                        yAxisMax={6}
                        width="800"
                        height="200"
                        xAxis={this.state.xAxis}
                        xAxisTitle={this.state.xAxisTitle}
                        xCategories={this.state.xCategories}
                        xCatStrict={this.state.xCatStrict}
                    />
                </div>

            </div>
        );
    }
}

export default ContentMrs;