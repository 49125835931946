import {NavLink, useLocation, withRouter} from 'react-router-dom';
import React from "react"
import {getFilterValues} from "../utils/Utils";

class MyNavbar extends React.Component {

    constructor(props) {
        super(props);

        this.selectRegionRef = React.createRef();
        this.selectHospitalRef = React.createRef();

        let filters = {
            region: '',
            hospital: ''
        }
        if (this.props.filters !== undefined) {
            filters = this.props.filters;
        }
        this.state = {
            data: props.data,
            filterValues: getFilterValues(props.data, filters),
            filters: filters
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if ((nextProps.filters.region === prevState.filters.region)
            && (nextProps.filters.hospital === prevState.filters.hospital)
            && (nextProps.data === prevState.data)
        ) {
            return null;
        }
        let fValues = getFilterValues(nextProps.data, nextProps.filters);
        return {
            data: nextProps.data,
            filterValues: fValues,
            filters: nextProps.filters
        }
    }

    handleFilterChange = () => {
        var region = this.selectRegionRef.current.value;
        var hospital = this.selectHospitalRef.current.value;
        if (this.state.filters !== undefined && region !== this.state.filters.region) {
            this.selectHospitalRef.current.value = '';
            hospital = '';
        }
        this.props.onChangeFilters({region: region, hospital: hospital});
    }

    render() {
        return (
            <nav className="navbar">
                <h1>Графічний аналіз даних інсультного реєстру</h1>
                <div className="links">
                    <div className="filtersBox">
                        <select onChange={this.handleFilterChange} ref={this.selectRegionRef}>
                            <option key='' value=''>Вся Україна</option>
                            {this.state.filterValues.regions.map((e, key) => {
                                return <option key={e} value={e}>{e}</option>
                            })}
                        </select>
                        <select onChange={this.handleFilterChange} ref={this.selectHospitalRef}>
                            <option key='' value=''>Всі лікарні</option>
                            {this.state.filterValues.hospitals.map((e, key) => {
                                return <option key={e} value={e}>{e}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <NavLink to="/mrs" activeClassName="active-nav-link">Залежність mRS від параметрів</NavLink>
                        <NavLink to="/recanalization" activeClassName="active-nav-link">Залежність часу до реканалізації від LVO</NavLink>
                        <NavLink to="/lvo" activeClassName="active-nav-link">Статистика виявлення LVO</NavLink>
                        <NavLink to="/trombextraction" activeClassName="active-nav-link">Залежність mRS від проведення тромбекстракції</NavLink>
                        <NavLink to="/etici" activeClassName="active-nav-link">Залежність mRS від eTICI</NavLink>
                        <NavLink to="/timeline" activeClassName="active-nav-link">Статистика витрат часу</NavLink>
                        <NavLink to="/pie" activeClassName="active-nav-link">Статистика пацієнтів</NavLink>
                        <NavLink to="/hospitals" activeClassName="active-nav-link">Статистика успішних тромбектомій за лікарнею</NavLink>
                    </div>
                </div>
            </nav>
        )
    }
}

export default withRouter(MyNavbar);