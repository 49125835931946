import React from "react";
import Chart from "react-apexcharts";
import {getLabelValue, getValueByPath, testValue} from "../utils/Utils";

class BoxPlot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            yAxis: props.yAxis,
            xAxis: props.xAxis,
            xCategories: props.xCategories,
            xCatStrict: props.xCatStrict
        };

        let yAxis = {};
        if (props.yAxisMin !== undefined && props.yAxisMax !== undefined) {
            yAxis = {
                min: props.yAxisMin,
                max: props.yAxisMax,
                forceNiceScale: true
            }
        }
        this.state.series = [
        ];
        this.state.options = {
            chart: {
                defaultLocale: 'ua',
                locales: [
                    {
                        "name": "ua",
                        "options": {
                            "months": [
                                "Січень",
                                "Лютий",
                                "Березень",
                                "Квітень",
                                "Травень",
                                "Червень",
                                "Липень",
                                "Серпень",
                                "Вересень",
                                "Жовтень",
                                "Листопад",
                                "Грудень"
                            ],
                            "shortMonths": [
                                "Січ",
                                "Лют",
                                "Бер",
                                "Кві",
                                "Тра",
                                "Чер",
                                "Лип",
                                "Сер",
                                "Вер",
                                "Жов",
                                "Лис",
                                "Гру"
                            ],
                            "days": [
                                "Неділя",
                                "Понеділок",
                                "Вівторок",
                                "Середа",
                                "Четвер",
                                "П'ятниця",
                                "Субота"
                            ],
                            "shortDays": ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                            "toolbar": {
                                "exportToSVG": "Зберегти SVG",
                                "exportToPNG": "Зберегти PNG",
                                "exportToCSV": "Зберегти CSV",
                                "menu": "Меню",
                                "selection": "Вибір",
                                "selectionZoom": "Вибір із збільшенням",
                                "zoomIn": "Збільшити",
                                "zoomOut": "Зменшити",
                                "pan": "Переміщення",
                                "reset": "Скинути збільшення"
                            }
                        }
                    },
                    {
                        "name": "en",
                        "options": {
                            "months": [
                                "January",
                                "February",
                                "March",
                                "April",
                                "May",
                                "June",
                                "July",
                                "August",
                                "September",
                                "October",
                                "November",
                                "December"
                            ],
                            "shortMonths": [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec"
                            ],
                            "days": [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                            "toolbar": {
                                "exportToSVG": "Download SVG",
                                "exportToPNG": "Download PNG",
                                "exportToCSV": "Download CSV",
                                "menu": "Menu",
                                "selection": "Selection",
                                "selectionZoom": "Selection Zoom",
                                "zoomIn": "Zoom In",
                                "zoomOut": "Zoom Out",
                                "pan": "Panning",
                                "reset": "Reset Zoom"
                            }
                        }
                    }
                ],
                height: 350
            },
            colors: ['#008FFB', '#FEB019'],
            title: {
                text: props.yAxisTitle,
                align: 'left'
            },
            xaxis: {
                type: 'category',
                categories: []
            },
            yaxis: yAxis,
            tooltip: {
                shared: false,
                intersect: true,
                custom: ({seriesIndex, dataPointIndex, w}) => {
                    return _getBoxTooltip(
                        w,
                        seriesIndex,
                        dataPointIndex,
                        ['Мінінмум', 'Перша квартиль', 'Медіана', 'Третя квартиль', 'Максімум'],
                        'boxPlot'
                    )
                }
            }
        };
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        if (currentState.data === nextProps.data && currentState.xAxis === nextProps.xAxis) {
            return null;
        } else {
            return BoxPlot.processData(nextProps.data, nextProps);
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined) {
            this.setState(BoxPlot.processData(this.props.data, this.props));
        }
    }

    static processData(rawData, props) {
        const subsets = [];
        for (let j = 0; j < props.xCategories.length; j++) {
            subsets.push([]);
        }

        for (let i = 0; i < rawData.length; i++) {
            let caseRecord = rawData[i];

            for (let j = 0; j < props.xCategories.length; j++) {
                let caseParameter = getValueByPath(caseRecord, props.xAxis);

                if (testValue(caseParameter, props.xCatStrict, props.xCategories[j])) {
                    subsets[j].push(caseRecord);
                    break;
                }
            }
        }

        const newData = [];
        const yAxis = props.yAxis;
        for (let i = 0; i < subsets.length; i++) {
            subsets[i].sort(function(a, b) {
               return getValueByPath(a, yAxis)-getValueByPath(b, yAxis);
            });
            newData[i] = {x: 0, y: []};
            newData[i].x = getLabelValue(props.xCatStrict, props.xCategories[i]);
            newData[i].y = [];
            if (subsets[i].length > 0) {
                newData[i].y.push(getValueByPath(subsets[i][0], yAxis));
                newData[i].y.push(BoxPlot.quartile(subsets[i], yAxis, 0.25));
                newData[i].y.push(BoxPlot.quartile(subsets[i], yAxis, 0.5));
                newData[i].y.push(BoxPlot.quartile(subsets[i], yAxis, 0.75));
                newData[i].y.push(getValueByPath(subsets[i][subsets[i].length - 1], yAxis));
            }
        }
        let newAxis = [];
        for (let i = 0; i < newData.length; i++) {
            newAxis.push(newData[i].x);
        }
        let ser = {
            name: 'box',
            type: 'boxPlot',
            data: newData
        };
        return {
            options: {
                xaxis: {
                    categories: newAxis
                }
            },
            series: [
                ser
            ]
        };
    }

    static quartile(data, yAxis, q) {
        let pos = ((data.length) - 1) * q;
        let base = Math.floor(pos);
        let rest = pos - base;
        if (data[base+1]!==undefined) {
            return getValueByPath(data[base], yAxis) + rest * (getValueByPath(data[base+1], yAxis) - getValueByPath(data[base], yAxis));
        } else {
            return getValueByPath(data[base], yAxis);
        }
    }

    render() {
        return (
            <div>
                <div className="mixed-chart">
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="boxPlot"
                        width={this.props.width}
                        height={this.props.height}
                    />
                </div>
            </div>
        );
    }
}

function _getBoxTooltip(w, seriesIndex, dataPointIndex, labels, chartType) {
    const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex]
    const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex]
    const m = w.globals.seriesCandleM[seriesIndex][dataPointIndex]
    const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex]
    const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex]

    if (
        w.config.series[seriesIndex].type &&
        w.config.series[seriesIndex].type !== chartType
    ) {
        return `<div class="apexcharts-custom-tooltip">
          ${
            w.config.series[seriesIndex].name
                ? w.config.series[seriesIndex].name
                : 'series-' + (seriesIndex + 1)
        }: <strong>${w.globals.series[seriesIndex][dataPointIndex]}</strong>
        </div>`;
    } else {
        return (
            `<div class="apexcharts-tooltip-box apexcharts-tooltip-${w.config.chart.type}">` +
            `<div>${labels[0]}: <span class="value">` +
            o +
            '</span></div>' +
            `<div>${labels[1]}: <span class="value">` +
            h +
            '</span></div>' +
            (m
                ? `<div>${labels[2]}: <span class="value">` + m + '</span></div>'
                : '') +
            `<div>${labels[3]}: <span class="value">` +
            l +
            '</span></div>' +
            `<div>${labels[4]}: <span class="value">` +
            c +
            '</span></div>' +
            '</div>'
        );
    }
}

export default BoxPlot;