import React from "react";
import StackedBar from "../charts/StackedBar";
import {filterData} from "../utils/Utils";
import {Tab, Tabs} from "react-bootstrap";

class ContentTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            xAxis: 'mRS24h',
            xCategories: [{eq: 0}, {eq: 1}, {eq: 2}, {eq: 3}, {eq: 4}, {eq: 5}, {eq: 6}],
            xCatStrict: true,
            yAxis: 'trombextraction.done',
            yCategories: [{eq: 'Ні'}, {eq: 'Так'}],
            yCatStrict: true,
            yLabels: ['Не проведено', 'Проведено']
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            data: filterData(nextProps.data, nextProps.filters)
        }
    }

    toggleX(e) {
        switch (e) {
            case 'mrsLeave':
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'mRSLeave',
                    xAxisTitle: 'mRS при виписці'
                }));
                break;
            case 'mrs90d':
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'mRS90d',
                    xAxisTitle: 'mRS через 90 днів'
                }));
                break;
            default:
                this.setState(state => ({
                    ...this.state,
                    xAxis: 'mRS24h',
                    xAxisTitle: 'mRS через 24 години'
                }));
        }
    }

    render() {
        return (
            <div>
                <div className="mixed-chart">
                    <Tabs defaultActiveKey="mrs24h" onSelect={(e) => this.toggleX(e)} className="mb-3 restyle">
                        <Tab eventKey="mrs24h" title="через 24 години"></Tab>
                        <Tab eventKey="mrsLeave" title="при виписці"></Tab>
                        <Tab eventKey="mrs90d" title="через 90 днів"></Tab>
                    </Tabs>

                    <StackedBar
                        id="plot"
                        data={this.state.data}
                        dataFilter={(obj) => {return obj.lvoDiscovery.startsWith('Виявлено')}}
                        width="800"
                        title={"Залежність mRS-24 від проведення тромбекстракції (тільки серед пацієнтів з виявленим LVO)"}
                        xAxis={this.state.xAxis}
                        xCategories={this.state.xCategories}
                        xCatStrict={this.state.xCatStrict}
                        yAxis={this.state.yAxis}
                        yCategories={this.state.yCategories}
                        yCatStrict={this.state.yCatStrict}
                        yLabels={this.state.yLabels}
                        stackType={'100%'}
                        colors={["#1fa123", "#1f8c3f", "#1f7765", "#1f6270", "#1f498a", "#0e2e60", "#08172e"]}
                    />
                </div>

            </div>
        );
    }
}

export default ContentTimeline;