import React from "react";
import BoxPlot from "../charts/BoxPlot";
import {filterData} from "../utils/Utils";

class ContentRecanalization extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            data: filterData(nextProps.data, nextProps.filters)
        }
    }

    render() {
        return (
            <div>
                <div className="mixed-chart compact">
                    <BoxPlot
                        id="bar"
                        data={this.state.data}
                        yAxis="trombextraction.doorToGroinePuncture"
                        yAxisTitle="Час від дверей до голки"
                        width="800"
                        height="300"
                        xAxis='lvoDiscovery'
                        xAxisTitle='LVO виявлено'
                        xCategories={[{eq: 'Виявлено при КТ чи МР скануванні'}, {eq: 'Виявлено при ДСЦА'}]}
                        xCatStrict={true}
                    />
                </div>
                <div className="mixed-chart compact">
                    <BoxPlot
                        id="bar"
                        data={this.state.data}
                        yAxis="trombextraction.doorToRecanalization"
                        yAxisTitle="Час від дверей до реканалізації"
                        width="800"
                        height="300"
                        xAxis='lvoDiscovery'
                        xAxisTitle='LVO виявлено'
                        xCategories={[{eq: 'Виявлено при КТ чи МР скануванні'}, {eq: 'Виявлено при ДСЦА'}]}
                        xCatStrict={true}
                    />
                </div>

            </div>
        );
    }
}

export default ContentRecanalization;