import React from "react";
import Chart from "react-apexcharts";
import {getLabelValue, getValueByPath, testValue} from "../utils/Utils";

class StackedBar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,

            xAxis: props.xAxis,
            xCategories: props.xCategories,
            xCatStrict: props.xCatStrict,
            yAxis: props.yAxis,
            yCategories: props.yCategories,
            yCatStrict: props.yCatStrict,
            yLabels: props.yLabels,
            colors: props.colors
        }

        this.state.series = [];

        this.state.options = {
            chart: {
                type: 'bar',
                height: 350,
                stacked: true,
                defaultLocale: 'ua',
                toolbar: {
                    show: false,
                    tools: {
                        download: false
                    }
                },
                locales: [
                    {
                        "name": "ua",
                        "options": {
                            "months": [
                                "Січень",
                                "Лютий",
                                "Березень",
                                "Квітень",
                                "Травень",
                                "Червень",
                                "Липень",
                                "Серпень",
                                "Вересень",
                                "Жовтень",
                                "Листопад",
                                "Грудень"
                            ],
                            "shortMonths": [
                                "Січ",
                                "Лют",
                                "Бер",
                                "Кві",
                                "Тра",
                                "Чер",
                                "Лип",
                                "Сер",
                                "Вер",
                                "Жов",
                                "Лис",
                                "Гру"
                            ],
                            "days": [
                                "Неділя",
                                "Понеділок",
                                "Вівторок",
                                "Середа",
                                "Четвер",
                                "П'ятниця",
                                "Субота"
                            ],
                            "shortDays": ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                            "toolbar": {
                                "exportToSVG": "Зберегти SVG",
                                "exportToPNG": "Зберегти PNG",
                                "exportToCSV": "Зберегти CSV",
                                "menu": "Меню",
                                "selection": "Вибір",
                                "selectionZoom": "Вибір із збільшенням",
                                "zoomIn": "Збільшити",
                                "zoomOut": "Зменшити",
                                "pan": "Переміщення",
                                "reset": "Скинути збільшення"
                            }
                        }
                    },
                    {
                        "name": "en",
                        "options": {
                            "months": [
                                "January",
                                "February",
                                "March",
                                "April",
                                "May",
                                "June",
                                "July",
                                "August",
                                "September",
                                "October",
                                "November",
                                "December"
                            ],
                            "shortMonths": [
                                "Jan",
                                "Feb",
                                "Mar",
                                "Apr",
                                "May",
                                "Jun",
                                "Jul",
                                "Aug",
                                "Sep",
                                "Oct",
                                "Nov",
                                "Dec"
                            ],
                            "days": [
                                "Sunday",
                                "Monday",
                                "Tuesday",
                                "Wednesday",
                                "Thursday",
                                "Friday",
                                "Saturday"
                            ],
                            "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                            "toolbar": {
                                "exportToSVG": "Download SVG",
                                "exportToPNG": "Download PNG",
                                "exportToCSV": "Download CSV",
                                "menu": "Menu",
                                "selection": "Selection",
                                "selectionZoom": "Selection Zoom",
                                "zoomIn": "Zoom In",
                                "zoomOut": "Zoom Out",
                                "pan": "Panning",
                                "reset": "Reset Zoom"
                            }
                        }
                    }
                ]
            },
            plotOptions: {
                bar: {
                    horizontal: true,
                },
            },
            stroke: {
                width: 1,
                colors: ['#fff']
            },
            title: {
                text: props.title
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                    }
                }
            }],
            xaxis: {
                categories: props.yLabels,
            },
            colors: props.colors,
            fill: {
                opacity: 1
            },
            legend: {
                position: 'top',
                horizontalAlign: 'left',
                offsetX: 40
            }
        };

        if (this.props.stackType !== undefined) {
            this.state.options.chart.stackType = this.props.stackType;
        }
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        if (currentState.data === nextProps.data) {
            return null;
        } else {
            return StackedBar.processData(nextProps.data, nextProps);
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined) {
            this.setState(StackedBar.processData(this.props.data, this.props));
        }
    }

    static processData(rawData, props) {
        const yNames = [];
        for (let i = 0; i < props.yCategories.length; i++) {
            yNames[i] = getLabelValue(props.yCatStrict, props.yCategories[i]);
        }

        const groupedData = new Array(props.xCategories.length);
        for (let i = 0; i < groupedData.length; i++) {
            groupedData[i] = [];
            for (let j = 0; j < props.yCategories.length; j++) {
                groupedData[i][j] = [];
            }
        }
        for (let i = 0; i < rawData.length; i++) {
            if (!props.dataFilter(rawData[i])) {
                continue;
            }
            let caseRecord = rawData[i];

            for (let j = 0; j < props.yCategories.length; j++) {
                if (testValue(getValueByPath(caseRecord, props.yAxis), props.yCatStrict, props.yCategories[j])) {
                    for (let k = 0; k < props.xCategories.length; k++) {
                        if (testValue(getValueByPath(caseRecord, props.xAxis), props.xCatStrict, props.xCategories[k])) {
                            groupedData[k][j].push(caseRecord);
                            break;
                        }
                    }
                    break;
                }
            }
        }
        let ser = [];
        for (let i = 0; i < props.xCategories.length; i++) {
            ser[i] = [];
            ser[i]['name'] = getLabelValue(props.xCatStrict, props.xCategories[i]);
            ser[i]['data'] = [];
            for (let j = 0; j < props.yCategories.length; j++) {
                if (groupedData[i][j].length > 0) {
                    ser[i]['data'][j] = groupedData[i][j].length;
                } else {
                    ser[i]['data'][j] = 0;
                }
            }
        }

        return {
            options: {
                xaxis: {
                    categories: props.yLabels
                },
                z: Math.random() // workaround to trigger update when options do not change
            },
            series: ser
        };
    }

    render() {
        return (
            <div>
                <div className="mixed-chart">
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        width={this.props.width}
                    />
                </div>
            </div>
        );
    }
}



export default StackedBar;