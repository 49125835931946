import React from "react";
import {filterData, getValueByPath} from "../utils/Utils";
import TimeBar from "../charts/TimeBar";
import {Tab, Tabs} from "react-bootstrap";

class ContentTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: props.data,
            xCategories: [
                {
                    name: 'Час до дверей',
                    getTime: (obj) => {
                        return getValueByPath(obj, 'doorTime.timeToDoor')
                    }
                },
                {
                    name: 'Початок тромболізісу',
                    getTime: (obj) => {
                        if (getValueByPath(obj, 'trombolisis.done') !== 'Так') {
                            return 0;
                        }
                        return getValueByPath(obj, 'trombolisis.doorToNeedle');
                    }
                },
                {
                    name: 'Початок тромбектомії',
                    getTime: (obj) => {
                        if (getValueByPath(obj, 'trombextraction.done') !== 'Так') {
                            return 0;
                        }
                        let doorToNeedle = 0;
                        if (getValueByPath(obj, 'trombolisis.done') === 'Так') {
                            doorToNeedle = getValueByPath(obj, 'trombolisis.doorToNeedle');
                        }
                        return getValueByPath(obj, 'trombextraction.doorToGroinePuncture') - doorToNeedle;
                    }
                },
                {
                    name: 'Реканалізація',
                    getTime: (obj) => {
                        if (getValueByPath(obj, 'trombextraction.done') !== 'Так') {
                            return 0;
                        }
                        return getValueByPath(obj, 'trombextraction.doorToRecanalization') - getValueByPath(obj, 'trombextraction.doorToGroinePuncture');
                    }
                }
            ],
            yCatStrict: true,
            yAxis: 'urban',
            yCategories: [{eq: 'Міський'}, {eq: 'Сільський'}, {eq: 'Внутрішньо-лікарняний'}],
            yAxisTitle: 'Міський або сільський мешканець'
        };

        this.toggleX = this.toggleX.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            data: filterData(nextProps.data, nextProps.filters)
        }
    }

    toggleX(e) {
        switch (e) {
            case 'prenotification' :
                this.setState(state => ({
                    ...this.state,
                    yAxis: 'prenotification',
                    yAxisTitle: 'Чи була пренотифікація',
                    yCatStrict: true,
                    yCategories: [{eq: 'Так'}, {eq: 'Ні'}]
                }));
                break;

            default:
                this.setState(state => ({
                    ...this.state,
                    yAxis: 'urban',
                    yAxisTitle: 'Міський або сільський мешканець',
                    yCatStrict: true,
                    yCategories: [{eq: 'Міський'}, {eq: 'Сільський'}, {eq: 'Внутрішньо-лікарняний'}]
                }));
                break;
        }
    }

    render() {
        return (
            <div>
                <Tabs defaultActiveKey="urban" onSelect={(e) => this.toggleX(e)} className="mb-3 restyle">
                    <Tab eventKey="urban" title="Міський/Сільський мешканець"></Tab>
                    <Tab eventKey="prenotification" title="Пренотифікація"></Tab>
                </Tabs>

                <div className="mixed-chart">
                    <TimeBar
                        id="plot"
                        data={this.state.data}
                        width="800"
                        xCategories={this.state.xCategories}
                        yCategories={this.state.yCategories}
                        yCatStrict={this.state.yCatStrict}
                        yAxis={this.state.yAxis}
                        yAxisTitle={this.state.yAxisTitle}
                    />
                </div>

            </div>
        );
    }
}

export default ContentTimeline;