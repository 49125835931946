import React from "react";
import ContentMrs from "./pages/ContentMrs";
import 'bootstrap/dist/css/bootstrap.min.css'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import MyNavbar from "./pages/MyNavbar";
import ContentLvo from "./pages/ContentLvo";
import ContentRecanalization from "./pages/ContentRecanalization";
import ContentPie from "./pages/ContentPie";
import ContentTimeline from "./pages/ContentTimeline";
import ContentTrombextraction from "./pages/ContentTrombextraction";
import ContentETICI from "./pages/ContentETICI";
import ContentHospital from "./pages/ContentHospital";

import dataPatients from './data/data.json';
import dataHospitals from './data/hospitals.json'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoadedPatients: false,
            dataPatients: this.purify(dataPatients),
            isLoadedHospitals: false,
            dataHospitals: this.purify(dataHospitals),
            filters: {
                region: '',
                hospital: ''
            }
        }
    }

    componentDidMount() {
/*
        const abortCont = new AbortController();
        fetch('data.json'
            , {
                signal: abortCont.signal,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => res.json())
            .then(res => this.purify(res))
            .then((res) => {
                this.setState({
                    ...this.state,
                    isLoadedPatients: true,
                    dataPatients: res
                });
            }, (error) => {
                if (error.name === 'AbortError') {
                    console.log("Fetch aborted");
                } else {
                    this.setState({
                        isLoadedPatients: true,
                        error
                    });
                }
            });
        fetch('hospitals.json'
            , {
                signal: abortCont.signal,
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(res => res.json())
            .then(res => this.purify(res))
            .then((res) => {
                this.setState({
                    ...this.state,
                    isLoadedHospitals: true,
                    dataHospitals: res
                });
            }, (error) => {
                if (error.name === 'AbortError') {
                    console.log("Fetch aborted");
                } else {
                    this.setState({
                        isLoadedHospitals: true,
                        error
                    });
                }
            });
        //abortCont.abort();
*/
    }

    purify(data) {
        let result = [];
        data.forEach(entry => {
            let objEntry = {};
            let parentNode = {};
            for (const [key, value] of Object.entries(entry)) {
                let path = key.split(".");
                let node = objEntry;
                // eslint-disable-next-line no-loop-func
                path.forEach(pathEntry => {
                    if (!node[pathEntry]) {
                        node[pathEntry] = {};
                    }
                    parentNode = node;
                    node = node[pathEntry];
                });
                parentNode[path[path.length-1]] = value;
            }
            result.push(objEntry);
        });
        return result;
    }

    handleChangeFilters = (filters) => {
        this.setState({
            ...this.state,
            filters: filters
        })
    }

    render() {
        return (
            <Router>
                <div className="app">
                    <MyNavbar data={new Array(this.state.dataPatients, this.state.dataHospitals)} onChangeFilters={this.handleChangeFilters} filters={this.state.filters}></MyNavbar>
                    <div className="content">
                        <Switch>
                            <Route exact path="/mrs">
                                <ContentMrs data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/recanalization">
                                <ContentRecanalization data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/lvo">
                                <ContentLvo data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/trombextraction">
                                <ContentTrombextraction data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/etici">
                                <ContentETICI data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/timeline">
                                <ContentTimeline data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/pie">
                                <ContentPie data={this.state.dataPatients} filters={this.state.filters}/>
                            </Route>
                            <Route exact path="/hospitals">
                                <ContentHospital data={this.state.dataHospitals} filters={this.state.filters}/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Router>
        );
    }
}

export default App;