import React from "react";
import Chart from "react-apexcharts";
import {getLabelValue, getValueByPath, testValue} from "../utils/Utils";

class LvoFoundColumn extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

            data: props.data,
            yAxis: props.yAxis,
            yCategories: props.yCategories,
            yCatStrict: props.yCatStrict,
            xAxis: props.xAxis,
            xCategories: props.xCategories,
            xCatStrict: props.xCatStrict,

            series: [],

            options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    stackType: '100%',
                    defaultLocale: 'ua',
                    locales: [
                        {
                            "name": "ua",
                            "options": {
                                "months": [
                                    "Січень",
                                    "Лютий",
                                    "Березень",
                                    "Квітень",
                                    "Травень",
                                    "Червень",
                                    "Липень",
                                    "Серпень",
                                    "Вересень",
                                    "Жовтень",
                                    "Листопад",
                                    "Грудень"
                                ],
                                "shortMonths": [
                                    "Січ",
                                    "Лют",
                                    "Бер",
                                    "Кві",
                                    "Тра",
                                    "Чер",
                                    "Лип",
                                    "Сер",
                                    "Вер",
                                    "Жов",
                                    "Лис",
                                    "Гру"
                                ],
                                "days": [
                                    "Неділя",
                                    "Понеділок",
                                    "Вівторок",
                                    "Середа",
                                    "Четвер",
                                    "П'ятниця",
                                    "Субота"
                                ],
                                "shortDays": ["Нд", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
                                "toolbar": {
                                    "exportToSVG": "Зберегти SVG",
                                    "exportToPNG": "Зберегти PNG",
                                    "exportToCSV": "Зберегти CSV",
                                    "menu": "Меню",
                                    "selection": "Вибір",
                                    "selectionZoom": "Вибір із збільшенням",
                                    "zoomIn": "Збільшити",
                                    "zoomOut": "Зменшити",
                                    "pan": "Переміщення",
                                    "reset": "Скинути збільшення"
                                }
                            }
                        },
                        {
                            "name": "en",
                            "options": {
                                "months": [
                                    "January",
                                    "February",
                                    "March",
                                    "April",
                                    "May",
                                    "June",
                                    "July",
                                    "August",
                                    "September",
                                    "October",
                                    "November",
                                    "December"
                                ],
                                "shortMonths": [
                                    "Jan",
                                    "Feb",
                                    "Mar",
                                    "Apr",
                                    "May",
                                    "Jun",
                                    "Jul",
                                    "Aug",
                                    "Sep",
                                    "Oct",
                                    "Nov",
                                    "Dec"
                                ],
                                "days": [
                                    "Sunday",
                                    "Monday",
                                    "Tuesday",
                                    "Wednesday",
                                    "Thursday",
                                    "Friday",
                                    "Saturday"
                                ],
                                "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                                "toolbar": {
                                    "exportToSVG": "Download SVG",
                                    "exportToPNG": "Download PNG",
                                    "exportToCSV": "Download CSV",
                                    "menu": "Menu",
                                    "selection": "Selection",
                                    "selectionZoom": "Selection Zoom",
                                    "zoomIn": "Zoom In",
                                    "zoomOut": "Zoom Out",
                                    "pan": "Panning",
                                    "reset": "Reset Zoom"
                                }
                            }
                        }
                    ]
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        legend: {
                            position: 'bottom',
                            offsetX: -10,
                            offsetY: 0
                        }
                    }
                }],
                title: {
                    text: props.title
                },
                xaxis: {
                    categories: [],
                },
                fill: {
                    opacity: 1
                },
                legend: {
                    position: 'right',
                    offsetX: 0,
                    offsetY: 50
                },
            }
        };
    }

    static getDerivedStateFromProps(nextProps, currentState) {
        if (currentState.data === nextProps.data && currentState.xAxis === nextProps.xAxis) {
            return null;
        } else {
            return LvoFoundColumn.processData(nextProps.data, nextProps);
        }
    }

    componentDidMount() {
        if (this.props.data !== undefined) {
            this.setState(LvoFoundColumn.processData(this.props.data, this.props));
        }
    }

    static processData(rawData, props) {
        const matrix = [];

        for (let i=0; i<props.yCategories.length; i++) {
            matrix[i] = new Array(props.xCategories.length);
            for (let j=0; j<props.xCategories.length; j++) {
                matrix[i][j] = 0;
            }
        }

        for (let i = 0; i < rawData.length; i++) {
            let caseRecord = rawData[i];

            let xCoord = undefined;
            let yCoord = undefined;
            for (let j = 0; j < props.xCategories.length; j++) {
                if (testValue(getValueByPath(caseRecord, props.xAxis), props.xCatStrict, props.xCategories[j])) {
                    xCoord = j;
                    break;
                }
            }
            for (let j = 0; j < props.yCategories.length; j++) {
                if (testValue(getValueByPath(caseRecord, props.yAxis), props.yCatStrict, props.yCategories[j])) {
                    yCoord = j;
                    break;
                }
            }
            if (xCoord !== undefined && yCoord !== undefined) {
                matrix[yCoord][xCoord]++;
            }
        }

        let ser = [];
        let xNames = [];
        let yNames = [];
        for (let i = 0; i < props.yCategories.length; i++) {
            yNames[i] = getLabelValue(props.yCatStrict, props.yCategories[i]);
            ser[i] = {name: yNames[i], data: matrix[i]};
        }
        for (let i = 0; i < props.xCategories.length; i++) {
            xNames[i] = getLabelValue(props.xCatStrict, props.xCategories[i]);
        }

        return {
            options: {
                xaxis: {
                    categories: xNames
                }
            },
            series: ser
        };
    }

    render() {
        return (
            <div>
                <div className="mixed-chart">
                    <Chart
                        options={this.state.options}
                        series={this.state.series}
                        type="bar"
                        width={this.props.width}
                    />
                </div>
            </div>
        );
    }
}



export default LvoFoundColumn;